import React from "react";
import { Routes, Route, Link, NavLink, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import logo from '../src/mb-logo.png';

import AppBar from '@mui/material/AppBar';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';

import MenuIcon from '@mui/icons-material/Menu';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
// import Twitter from '@mui/icons-material/Twitter';

import EmceeStandings from "./components/emceestandings";
import EmceeBattleHistory from "./components/emceebattlehistory";
import TeamStandings from "./components/teamstandings";
import TeamBattleHistory from "./components/teambattlehistory";
import EventList from "./components/eventlist";
import EventBattles from "./components/eventbattles";
import IsabuhayList from "./components/isabuhaylist";
import IsabuhayBattles from "./components/isabuhaybattles";
import DosPorDosList from "./components/dospordoslist";
import DosPorDosBatles from "./components/dospordosbattles";
import PrivacyPolicy from "./components/privacypolicy";
import HomePage from "./components/homepage";
import AdsComponent from "./components/AdsComponent";


function App() {
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElStandings, setAnchorElStandings] = React.useState(null);
  const [anchorElTournaments, setAnchorElTournaments] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  const handleClickStandings = (event) => {
    setAnchorElStandings(event.currentTarget);
  };

  const handleCloseStandings = () => {
    setAnchorElStandings(null);
  };

  const handleClickTournaments = (event) => {
    setAnchorElTournaments(event.currentTarget);
  };

  const handleCloseTournaments = () => {
    setAnchorElTournaments(null);
  };

  const openStandings = Boolean(anchorElStandings);
  const idStandings = openStandings ? 'menu-popover' : undefined;

  const openTournaments = Boolean(anchorElTournaments);
  const idTournaments = openTournaments ? 'menu-popover' : undefined;


  const pages = [
    {title:'Emcee Standings',link:'/standings/emcee'},
    {title:'Team Standings',link:'/standings/team'},
    {title:'Battle Events',link:'/events'},
    {title:'Isabuhay',link:'/tournaments/isabuhay'},
    {title:'Dos Por Dos',link:'/tournaments/dospordos'},
  ];

  
  const standingsMenu = [
    {title:'Emcee', link:'/standings/emcee' },
    {title:'Team',link:'/standings/team'},
  ];

  const tournamentsMenu = [
    {title:'Isabuhay', link:'/tournaments/isabuhay' },
    {title:'Dos Por Dos',link:'/tournaments/dospordos'},
  ];


  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <AppBar position="static">
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <Box
            component="a"
            sx={{ display: { xs: 'none', md: 'flex' }  }} 
            href="/"
          >
            <Box
              component="img"
              sx={{ display: { xs: 'none', md: 'flex' },
                    mr: 1,
                    height: 60,
                    width: 60, }} 
              alt="The website logo."
              src={logo} />
          </Box>
          <Typography
            variant="h8"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            FlipTop Battle <br/>
            League Standings
          </Typography>
          

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" component="a" href={page.link}  >{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

            <Box
            component="a"
            sx={{ display: { xs: 'flex', md: 'none' } }} 
            href="/"
          >
            <Box
              component="img"
              sx={{ display: { xs: 'flex', md: 'none' },
                    mr: 1,
                    height: 60,
                    width: 60, }} 
              alt="The website logo."
              src={logo} />
          </Box>
          <Typography
            variant="h8"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            FlipTop Battle <br/>
            League Standings
          </Typography>


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            <Button component={Link} 
              sx={{ my: 2, color: 'white', display: 'block', marginLeft: '30px' }}
              className={location.pathname ==="/standings/emcee" || location.pathname ==="/standings/team"  ? "highlighted" : undefined}  key="Emcee Standing" 
              onClick={handleClickStandings}   >
             Standings
            </Button>
              <Popover
                id={idStandings}
                open={openStandings}
                anchorEl={anchorElStandings}
                onClose={handleCloseStandings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {standingsMenu.map((menuItem) => ( 
                  <MenuItem key={menuItem.title}>
                    <Typography textAlign="center" component="a" href={menuItem.link} >{menuItem.title}</Typography>
                  </MenuItem>
                ))}     
              </Popover>

            <Button component={Link} 
              sx={{ my: 2, color: 'white', display: 'block', marginLeft: '30px' }}
              className={location.pathname ==="/tournaments/isabuhay" || location.pathname ==="/tournaments/dospordos"  ? "highlighted" : undefined}  key="Tournament" 
              onClick={handleClickTournaments}   >
             Tournaments
            </Button>
            <Popover
                id={idTournaments}
                open={openTournaments}
                anchorEl={anchorElTournaments}
                onClose={handleCloseTournaments}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {tournamentsMenu.map((menuItem) => ( 
                  <MenuItem key={menuItem.title}>
                    <Typography textAlign="center" component="a" href={menuItem.link} >{menuItem.title}</Typography>
                  </MenuItem>
                ))}     
            </Popover>


            <Button component={Link} to="/events" 
              sx={{ my: 2, color: 'white', display: 'block', marginLeft: '30px' }}
              className={"/events" === location.pathname ? "highlighted" : undefined}  key="Battle Events"   >
             Events
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    <Container maxWidth="xs" sx={{mt: 3}}> 
    <Card sx={{ minWidth: 240,mb:1 }}>
      <CardContent>
      <Grid container>
                      <Grid item xs={6}> 
                      <Typography variant="body2">
                      <b>Data as of:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <Typography variant="body4">
                        April 6, 2024
                      </Typography>
                      </Grid>
                  </Grid>
      </CardContent>
    </Card>
    </Container>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/standings/emcee" element={<EmceeStandings/>} />
          <Route path="/standings/emcee/:id" element={<EmceeBattleHistory/>} />
          <Route path="/standings/team" element={<TeamStandings/>} />
          <Route path="/standings/team/:id" element={<TeamBattleHistory/>} />
          <Route path="/events" element={<EventList/>} />
          <Route path="/events/:id" element={<EventBattles/>} />
          <Route path="/tournaments/isabuhay" element={<IsabuhayList/>} />
          <Route path="/tournaments/isabuhay/:id" element={<IsabuhayBattles/>} />
          <Route path="/tournaments/dospordos" element={<DosPorDosList/>} />
          <Route path="/tournaments/dospordos/:id" element={<DosPorDosBatles/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        </Routes>
        <Container maxWidth="xs" sx={{mt: 3}}> 
          <AdsComponent dataAdSlot='5272532038' />  
        </Container>       
        <Box
          component="footer"
          sx={{
            py: 1,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="lg">
          <Grid container spacing={5}>
          <Grid item xs={12} sm={5}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom >
              "Matchbox Stats" is dedicated to providing the win-loss statistics and match histories of sports, eSports, and other forms of competitions to all the respective fans and followers.
            </Typography>
            <Typography  component="a" href="/privacy-policy" >
              Check our Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              If you have any questions, feedbacks or suggestions about this website, please send us an email to:
            </Typography>
            <Link  color="inherit">
              matchboxstats@gmail.com
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <a href="https://www.facebook.com/profile.php?id=61554131695694" target='_blank' color="inherit">
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/matchboxstats/"
              color="inherit" target='_blank'
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </a>
       
             <Typography sx={{ mt:3 }} variant="h6" color="text.primary" >
              Affiliate Partners
            </Typography>
            <a href="https://amzn.to/3HVTk1j" target='_blank' color="inherit">
              Amazon
            </a>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © Matchbox - FlipTop Battle League Standings "}
            {" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>

          </Container>

        </Box>
    </Box>
  );
}

export default App;