import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Grid, Container } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import emceeStandings from "../data/EmceeStandings.json"


const AppTable = () => {
    const [data, setData] = useState([]);

    //send api request here
    const retrieveData = () => {
        setData(emceeStandings);
      };

      useEffect(() => {
        retrieveData();
      }, []);



  const columns = useMemo(
    () => [
      {
        header: 'Emcee',
        accessorFn: (row) => row.name,
        id: 'name',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (
          <a href={"emcee/" + row.original.id }> {row.original.name}</a>
        ),
      },
      {
        header: 'W',
        accessorFn: (row) => row.win,
        id: 'win',
        size: 52,
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
      },
      {
        header: 'L',
        accessorFn: (row) => row.lose,
        id: 'lose',
        size: 52,
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      expanded: false, //expand all groups by default
      pagination: { pageIndex: 0, pageSize: 30 },
      sorting: [{ id: 'name', desc: false }]
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
    
  });

  return  <Container maxWidth="sm" sx={{mt: 3,mb: 3}}> 
            <Typography variant="h6" color="text.primary" >
              Emcee Standings (Win- Loss Record)
            </Typography>
            <MaterialReactTable table={table}   />
          </Container>;
};

export default AppTable;
