import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Grid, Container, Card, CardContent } from '@mui/material';
import { useMatch  } from 'react-router-dom';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import emceeStandings from "../data/EmceeStandings.json"
import emceeBattles from "../data/EmceeBattles.json"


const AppTable = () => {

    const match = useMatch("/:firstRoute/:secondRoute/*");
    const lastRoute = match.params["*"];


    const [data, setData] = useState([]);
    const [emceeName, setEmceeName] = useState("");
    const [winLossRecord, setWinLossRecord] = useState("");
    const [singleRecord, setSingleRecord] = useState("");
    const [teamRecord, setTeamRecord] = useState("");
    const [twayRecord, setTwayRecord] = useState("");
    const [rrRecord, setRrRecord] = useState("");
    const [fvfRecord, setFvfRecord] = useState("");
    const [tvtRecord, setTvtRecord] = useState("");

    const [isabuhayRecord, setIsabuhayRecord] = useState("");
    const [dpdRecord, setDpdRecord] = useState("");
    const [tournamentRecord, setTournamentRecord] = useState("");
    const [firstBattle, setFirstBattle] = useState(new Date());
    const [lastBattle, setLastBattle] = useState(new Date());

    //send api request here
    const retrieveTutorials = () => {

      var emceeBattleList = emceeBattles.filter(row => row.Emcee.Id == lastRoute);
      setData(emceeBattleList);
      
      var teamBattles = emceeBattleList.filter(row => row.Battle.CategoryName == "2 on 2 acapella" || row.Battle.CategoryName == "2 on 2 freestyle acapella");
      var singleBattles = emceeBattleList.filter(row => row.Battle.CategoryName == "1 on 1 acapella" || row.Battle.CategoryName == "1 on 1 freestyle acapella" || row.Battle.CategoryName == "1 on 1 old school");
      var threeWayBattles = emceeBattleList.filter(row => row.Battle.CategoryName == "3 way acapella");
      var royalRumble = emceeBattleList.filter(row => row.Battle.CategoryName == "Royal Rumble (acapella)");
      var fiveOnFiveBattles = emceeBattleList.filter(row => row.Battle.CategoryName == "5 on 5");
      var threeOnThreeBattles = emceeBattleList.filter(row => row.Battle.CategoryName == "3 on 3 freestyle acapella");
            
      var isabuhayBattles = emceeBattleList.filter(row => row.Battle.Tournament.Type == "Isabuhay");
      var dosPosDosBattles = emceeBattleList.filter(row => row.Battle.Tournament.Type == "Dos Por Dos");
      var tournamentStandings = emceeBattleList.filter(row => row.Battle.Tournament.Id != 1);
        
      var tbWin = teamBattles.filter(row => (row.Battle.Winner == row.Team.Name));
      var tbLose = teamBattles.filter(row => !(row.Battle.Winner == row.Team.Name));
      var sbWin = singleBattles.filter(row => (row.Battle.Winner == row.Emcee.Name));
      var sbLose = singleBattles.filter(row => !(row.Battle.Winner == row.Emcee.Name) && (row.Battle.Outcome == "Judged" || row.Battle.Outcome == "No show") );
      var twayWin = threeWayBattles.filter(row => (row.Battle.Winner == row.Emcee.Name));
      var twayLose = threeWayBattles.filter(row => !(row.Battle.Winner == row.Emcee.Name) && (row.Battle.Outcome == "Judged" || row.Battle.Outcome == "No show") );         
      var fvfWin = fiveOnFiveBattles.filter(row => (row.Battle.Winner == row.Team.Name));
      var fvfLose = fiveOnFiveBattles.filter(row => !(row.Battle.Winner == row.Team.Name) && (row.Battle.Outcome == "Judged" || row.Battle.Outcome == "No show") );         
      var rrWin = royalRumble.filter(row => (row.Battle.Winner == row.Emcee.Name));
      var rrLose = royalRumble.filter(row => !(row.Battle.Winner == row.Emcee.Name) && (row.Battle.Outcome == "Judged" || row.Battle.Outcome == "No show") );
      var tvtWin = threeOnThreeBattles.filter(row => (row.Battle.Winner == row.Team.Name));
      var tvtLose = threeOnThreeBattles.filter(row => !(row.Battle.Winner == row.Team.Name) && (row.Battle.Outcome == "Judged" || row.Battle.Outcome == "No show") );
            
      var isbWin = isabuhayBattles.filter(row => (row.Battle.Winner == row.Emcee.Name));
      var isbLose = isabuhayBattles.filter(row => !(row.Battle.Winner == row.Emcee.Name));
      var dpdWin = dosPosDosBattles.filter(row => (row.Battle.Winner == row.Team.Name));
      var dpdLose = dosPosDosBattles.filter(row => !(row.Battle.Winner == row.Team.Name) && (row.Battle.Outcome == "Judged" || row.Battle.Outcome == "No show") );

      var tournaWin = tournamentStandings.filter(row => (row.Battle.Winner == row.Emcee.Name) || (row.Team != null && row.Battle.Winner == row.Team.Name) );
      var tournaLose = tournamentStandings.filter(row => !(row.Battle.Winner == row.Emcee.Name) && !(row.Team != null && row.Battle.Winner == row.Team.Name));

      setTeamRecord(tbWin.length + " - " + tbLose.length);
      setSingleRecord(sbWin.length + " - " + sbLose.length);
      setTwayRecord(twayWin.length + " - " + twayLose.length);
      setFvfRecord(fvfWin.length + " - " + fvfLose.length);
      setRrRecord(rrWin.length + " - " + rrLose.length);
      setTvtRecord(tvtWin.length + " - " + tvtLose.length);
      setIsabuhayRecord(isbWin.length + " - " + isbLose.length);
      setDpdRecord(dpdWin.length + " - " + dpdLose.length);
      setTournamentRecord(tournaWin.length + " - " + tournaLose.length);
        


      var emceeDetails = emceeStandings.filter(row => row.id == lastRoute);
      setEmceeName(emceeDetails[0].name);
      setFirstBattle(emceeDetails[0].first);
      setLastBattle(emceeDetails[0].latest);
      setWinLossRecord(emceeDetails[0].win + ' - ' + emceeDetails[0].lose);

      };

      useEffect(() => {
        retrieveTutorials();
        
      }, []);




  const columns = useMemo(
    () => [
      {
        header: 'Battle',
        accessorFn: (row) => row.Battle.Title,
        id: 'Battle.Title',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 150,
        Cell: ({ row }) => (
          <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}}> 
            <Typography  noWrap  sx={{ fontSize: 10, gap: '1rem' }}>
            {row.original.Battle.Title} </Typography>
            </div>
          ),
      },
      {
        header: 'W/L',
        accessorFn: (row) => (row.Battle.Winner == row.Emcee.Name) || (row.Team != null && row.Battle.Winner == row.Team.Name),
        id: 'winner',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 70,
        Cell: ({ row }) => (
            <Typography sx={{ 
             fontSize: 10
            , color:  (row.original.Battle.Winner == row.original.Emcee.Name) || (row.original.Team != null && row.original.Battle.Winner == row.original.Team.Name) ? 'green' : 'red' }}>
            {(row.original.Battle.Outcome!="Judged" 
            && row.original.Battle.Outcome!="No show") ? row.original.Battle.Outcome
                                                            : (row.original.Battle.Winner == row.original.Emcee.Name) || (row.original.Team != null && row.original.Battle.Winner == row.original.Team.Name) ? "Win" : "Lose"} </Typography>   

          ),
      },
      {
        header: 'Class',
        accessorFn: (row) => row.Battle.CategoryName,
        id: 'Battle.CategoryName',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (

            <Typography sx={{ fontSize: 10}}>
            {row.original.Battle.CategoryName} </Typography>

          ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      expanded: false, //expand all groups by default
      pagination: { pageIndex: 0, pageSize: 50 },
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
    renderDetailPanel: ({ row }) => (
        <Box
          sx={{
            display: 'grid',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography sx={{fontSize:10}}><b>Event: </b> {row.original.Battle.Event.Name}</Typography>
          <Typography sx={{fontSize:10}}><b>Date: </b>{new Date(row.original.Battle.Event.Date).toISOString().split('T')[0]}</Typography>
          <Typography sx={{fontSize:10}}><b>Conference: </b>{row.original.Battle.Language}</Typography>
          <Typography sx={{fontSize:10}}><b>Location: </b>{row.original.Battle.Event.Location}</Typography>
        </Box>
      )
  });

  return    <Container maxWidth="xs" sx={{mt: 3,mb: 3}}> 
            <Card sx={{ minWidth: 240,mb:1 }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {emceeName}
                  </Typography>
                  <Grid container sx={{mt: 1,mb: 1,borderBottom: '1px solid gray'}}>
                      <Grid item xs={3}> 
                      <Typography variant="body2">
                      <b>First Battle:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                      <Typography variant="body2">
                      {new Date(firstBattle).toISOString().split('T')[0]}  <br />
                      </Typography>
                      </Grid>
                      <Grid item xs={3}> 
                      <Typography variant="body2">
                      <b>Last Battle:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                      <Typography variant="body2">
                      {new Date(lastBattle).toISOString().split('T')[0]}  <br />
                      </Typography>
                      </Grid>
                  </Grid>
                  <Grid container sx={{mt: 1,mb: 1 }}>
                      <Grid item xs={4}> 
                        <Typography variant="body2">
                            <b>Total Win-Loss:</b> 
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="body2">
                          {winLossRecord}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}> 
                        <Typography variant="body2">
                            <b>Tournament Record:</b> 
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="body2">
                          {tournamentRecord}
                        </Typography>
                      </Grid>
                  </Grid>

                  <Grid container sx={{mt: 1,mb: 1, pl: 1,border: '1px solid gray'}}>
                      <Grid item xs={4}> 
                      <Typography variant="body2">
                        <b>1 on 1:</b>  <br />
                        <b>2 on 2:</b>  <br />
                        {tvtRecord!="0 - 0" && <b>3 on 3: <br /></b> }
                        {fvfRecord!="0 - 0" && <b>5 on 5: <br /></b> } 
                        {twayRecord!="0 - 0" && <b>3-way battle <br/></b> }
                        {rrRecord!="0 - 0" && <b>Royal Rumble: <br /></b> }
                        
                      </Typography>
                      </Grid>
                      <Grid item xs={2}>
                      <Typography variant="body2">
                        {singleRecord} <br />
                        {teamRecord}  <br />
                        {tvtRecord!="0 - 0" && <span> {tvtRecord} <br/></span>}
                        {fvfRecord!="0 - 0" && <span> {fvfRecord} <br/></span>}
                        {twayRecord!="0 - 0" && <span> {twayRecord} <br/></span>}
                        {rrRecord!="0 - 0" && <span> {rrRecord} <br/></span>}

                      </Typography>
                      </Grid>
                      <Grid item xs={4}> 
                      <Typography variant="body2">
                        <b>Isabuhay</b>  <br />
                        <b>Dos Por Dos:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={2}>
                      <Typography variant="body2">
                        {isabuhayRecord} <br />
                        {dpdRecord}  <br />
                      </Typography>
                      </Grid>
                  </Grid>
                </CardContent>
            </Card>
                <MaterialReactTable table={table} /> 
            </Container>;
};

export default AppTable;
