import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Grid, Container, Card, CardContent } from '@mui/material';
import { useMatch  } from 'react-router-dom';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import DosPorDosDetails from "../data/DosPorDosDetails.json";
import DosPorDosList from "../data/DosPorDosList.json";


const AppTable = () => {

    const match = useMatch("/:firstRoute/:secondRoute/*");
    const lastRoute = match.params["*"];


    const [data, setData] = useState([]);
    const [tournament, setTournament] = useState("");
    const [champion, setChampion] = useState("");
    const [finalist, setFinalist] = useState("");
    
    //send api request here
    const retrieveData = () => {

      var dpdTounamentBattles = DosPorDosDetails.filter(row => row.id == lastRoute).sort((a, b) => (a.eventdate < b.eventdate) ? 1 : -1);
      setData(dpdTounamentBattles);
      

      var tournament = DosPorDosList.filter(row => row.id == lastRoute);
      setTournament(tournament[0].tournament);
      setChampion(tournament[0].champion);
      setFinalist(tournament[0].finalist);


      };

      useEffect(() => {
        retrieveData();
        
      }, []);




  const columns = useMemo(
    () => [
      {
        header: 'Battle',
        accessorFn: (row) => row.battle,
        id: 'battle',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 200,
        // Cell: ({ row }) => (
        //   <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}}> 
        //     <Typography  noWrap  sx={{ fontSize: 10, gap: '1rem' }}>
        //     {row.original.battle} </Typography>
        //     </div>
        //   ),
        Cell: ({ row }) => (
          <Container  noWrap>
            <Typography sx={{ fontSize: 10, gap: '1rem' }}>{row.original.battle.split("vs")[0]}</Typography>
            <Typography sx={{ fontSize: 10, gap: '1rem' }}>vs</Typography>
            <Typography sx={{ fontSize: 10, gap: '1rem' }}>{row.original.battle.split("vs")[1]}</Typography>
          </Container>
        )
      },
      {
        header: 'Winner',
        accessorFn: (row) => row.winner ? "Win" : "Lose",
        id: 'winner',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Container  noWrap>
            <Typography sx={{ fontSize: 10, gap: '1rem' }}>{row.original.winner.split("/")[0]}</Typography>
            <Typography sx={{ fontSize: 10, gap: '1rem' }}>{row.original.winner.split("/")[1]}</Typography>
          </Container>
        )
      }

    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      expanded: false, //expand all groups by default
      pagination: { pageIndex: 0, pageSize: 50 },
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },

  });

  return    <Container maxWidth="xs" sx={{mt: 3,mb: 3}}> 
            <Card sx={{ minWidth: 240,mb:1 }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {tournament}
                  </Typography>
                  <Grid container sx={{mt: 1}}>
                      <Grid item xs={3}> 
                      <Typography variant="body2">
                      <b>Champion:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                      <Typography variant="body2">
                      {champion.split(" / ")[0]}
                      </Typography>
                      <Typography variant="body2">
                      {champion.split(" / ")[1]}
                      </Typography>
                      </Grid>
                      <Grid item xs={3}> 
                      <Typography variant="body2">
                      <b>Finalist:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                      <Typography variant="body2">
                      {finalist.split(" / ")[0]}
                      </Typography>
                      <Typography variant="body2">
                      {finalist.split(" / ")[1]}
                      </Typography>
                      </Grid>
                  </Grid>
           
      
                </CardContent>
            </Card>
                <MaterialReactTable table={table} /> 
            </Container>;
};

export default AppTable;
