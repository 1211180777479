import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Container } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import Events from  "../data/Events.json"


const Example = () => {


    const [data, setData] = useState([]);

    const retrieveData = () => {
        setData(Events);
      };

      useEffect(() => {
        retrieveData();
      }, []);



  const columns = useMemo(
    () => [
      {
            header: 'Year',
            accessorKey: 'Year',
            enableGrouping: false,
            size: 80
      },
      {
        header: 'Name',
        accessorFn: (row) => row.Name,
        id: 'Name',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 150,
        Cell: ({ row }) => (
          <a href={"events/" + row.original.Id }> {row.original.Name}</a>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showColumnFilters: true,
      expanded: false, //expand all groups by default
      grouping: ['Year'], //an array of columns to group by by default (can be multiple)
      pagination: { pageIndex: 0, pageSize: 20 },
      sorting: [{ id: 'Year', desc: false }], //sort defaults
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },

  });

  return <Container maxWidth="xs" sx={{mt: 3,mb: 3}}> 
          <Typography variant="h6" color="text.primary" >
            FlipTop Events by Year
          </Typography>
          <MaterialReactTable table={table}   />
        </Container>;;
};

export default Example;
