import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Link from '@mui/material/Link';

export default function PrivacyPolicy() {
  return (
        <Container  maxWidth="lg"        
        sx={{
            mt: 3
          }}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{mb: 3}}>
                December 11, 2023
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{mb: 3}}>
                    Thank you for visiting www.matchboxstats.com! We are committed to making sure that website visitors are fully aware of the information we are collecting from them. 
                    By using our website, you are acknowledging and understanding the privacy policy we have. 
                </Typography>
                <Typography variant="h6" color="text.primary" sx={{mb: 3}}>
                    Collection of Information
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{mb: 3, lineHeight: 2}}>
                The website at present does not collect any information about you. Although we are giving our visitors a way for them to contact us through our email address, email senders could voluntarily give their personal information.
                If we receive any personal information, we will never use it in any way and will not disclose it in any form.
                </Typography>
                <Typography variant="h6" color="text.primary" sx={{mb: 3}}>
                    Ads and Cookies
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{mb: 3, lineHeight: 2}}>
                We are planning to use this website as an advertising space. Usually, we will be subscribing to partner advertisers, who will be responsible for feeding us the ads that we will display on our website.
                Please be aware that partner advertisers will have different privacy policies, so we highly suggest reviewing their privacy policies to be aware of what personal information they are collecting.
                </Typography>
                <Typography variant="h6" color="text.primary" sx={{mb: 3}}>
                    Privacy Contact Information
                </Typography>
                <Typography variant="body2" color="text.secondary" >
                    If you need to clarify any information with regards to our privacy policy, you may contact us through our email below:
                </Typography>
                <Link variant="body2"  gutterBottom>
                matchboxstats@gmail.com
                </Link>
                <Typography variant="body2" color="text.secondary" sx={{mb: 3}}>
                Please be informed that this website recently went live (November 2023) and we are expecting more improvements to happen. 
                Rest assured that any website improvements that will affect personal information collection will be communicated and reflected on our privacy policy page.
                </Typography>
        </Container>
  );
}