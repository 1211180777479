import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Grid, Container } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import IsabuhayList from  "../data/IsabuhayList.json"


const AppTable = () => {


    const [data, setData] = useState([]);
    
    //send api request here
    const retrieveData = () => {
        setData(IsabuhayList);
      };

      useEffect(() => {
        retrieveData();
      }, []);



  const columns = useMemo(
    () => [
      {
        header: 'Emcee',
        accessorFn: (row) => row.tournament,
        id: 'name',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 120,
        Cell: ({ row }) => (
          <a href={"isabuhay/" + row.original.id }> {row.original.tournament}</a>
        ),
      },
      {
        header: 'Champion',
        accessorFn: (row) => row.champion,
        id: 'win',
        size: 120,
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
      },
      {
        header: 'Finalist',
        accessorFn: (row) => row.finalist,
        id: 'lose',
        size: 120,
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      expanded: false, //expand all groups by default
      pagination: { pageIndex: 0, pageSize: 50 },
      sorting: [{ id: 'name', desc: false }]
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
    
  });

  return  <Container maxWidth="xs" sx={{mt: 3,mb: 3}}> 
            <Typography variant="h6" color="text.primary" >
              FlipTop - Isabuhay Tournaments
            </Typography>
            <Typography variant="body2" color="text.secondary" >
                Please wait for the page to load
            </Typography>
            <MaterialReactTable table={table}   />
          </Container>;
};

export default AppTable;
