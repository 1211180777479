import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Grid, Container, Card, CardContent } from '@mui/material';
import { useMatch  } from 'react-router-dom';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import teamStandings from "../data/TeamStandings.json";
import emceeBattles from "../data/EmceeBattles.json";


const AppTable = () => {

    const match = useMatch("/:firstRoute/:secondRoute/*");
    const lastRoute = match.params["*"];


    const [data, setData] = useState([]);
    const [emceeName, setEmceeName] = useState("");
    const [winLossRecord, setWinLossRecord] = useState("");
    const [tournamentRecord, setTournamentRecord] = useState("");
    const [firstBattle, setFirstBattle] = useState(new Date());
    const [lastBattle, setLastBattle] = useState(new Date());

    //send api request here
    const retrieveData = () => {

      var teamBattleList = emceeBattles.filter(row => row.Team != null && row.Team.Id == lastRoute);


      const filteredBattles = [];
      teamBattleList.map((item) => {
        var findItem = filteredBattles.find((x) => x.Battle.Id === item.Battle.Id);
        if (!findItem) filteredBattles.push(item);
      });
      
      setData(filteredBattles);
      var dosPosDosBattles = filteredBattles.filter(row => row.Battle.Tournament.Type == "Dos Por Dos");
      var dpdWin = dosPosDosBattles.filter(row => (row.Battle.Winner == row.Team.Name));
      var dpdLose = dosPosDosBattles.filter(row => !(row.Battle.Winner == row.Team.Name) && (row.Battle.Outcome == "Judged" || row.Battle.Outcome == "No show") );

      setTournamentRecord(dpdWin.length + " - " + dpdLose.length);

      var teamDetails = teamStandings.filter(row => row.id == lastRoute);
      setEmceeName(teamDetails[0].name);
      setFirstBattle(teamDetails[0].first);
      setLastBattle(teamDetails[0].latest);
      setWinLossRecord(teamDetails[0].win + ' - ' + teamDetails[0].lose);

      };

      useEffect(() => {
        retrieveData();
        
      }, []);




  const columns = useMemo(
    () => [
      {
        header: 'Battle',
        accessorFn: (row) => row.Battle.Title,
        id: 'Battle.Title',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 150,
        Cell: ({ row }) => (
          <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}}> 
            <Typography  noWrap  sx={{ fontSize: 10, gap: '1rem' }}>
            {row.original.Battle.Title} </Typography>
            </div>
          ),
      },
      {
        header: 'W/L',
        accessorFn: (row) => (row.Team != null && row.Battle.Winner == row.Team.Name),
        id: 'winner',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 70,
        Cell: ({ row }) => (
            <Typography sx={{ 
             fontSize: 10
             , color:  (row.original.Battle.Winner == row.original.Emcee.Name) || (row.original.Team != null && row.original.Battle.Winner == row.original.Team.Name) ? 'green' : 'red' }}>
             {(row.original.Battle.Outcome!="Judged" 
             && row.original.Battle.Outcome!="No show") ? row.original.Battle.Outcome
                                                             : (row.original.Battle.Winner == row.original.Emcee.Name) || (row.original.Team != null && row.original.Battle.Winner == row.original.Team.Name) ? "Win" : "Lose"} </Typography>  

          ),
      },
      {
        header: 'Class',
        accessorFn: (row) => row.Battle.CategoryName,
        id: 'Battle.CategoryName',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (

            <Typography sx={{ fontSize: 10}}>
            {row.original.Battle.CategoryName} </Typography>

          ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      expanded: false, //expand all groups by default
      pagination: { pageIndex: 0, pageSize: 50 },
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
    renderDetailPanel: ({ row }) => (
        <Box
          sx={{
            display: 'grid',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography sx={{fontSize:10}}><b>Event: </b> {row.original.Battle.Event.Name}</Typography>
          <Typography sx={{fontSize:10}}><b>Date: </b>{new Date(row.original.Battle.Event.Date).toISOString().split('T')[0]}</Typography>
          <Typography sx={{fontSize:10}}><b>Conference: </b>{row.original.Battle.Language}</Typography>
          <Typography sx={{fontSize:10}}><b>Location: </b>{row.original.Battle.Event.Location}</Typography>
        </Box>
      )
  });

  return    <Container maxWidth="xs" sx={{mt: 3,mb: 3}}> 
            <Card sx={{ minWidth: 240,mb:1 }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {emceeName}
                  </Typography>
                  <Grid container sx={{mt: 1,mb: 1,borderBottom: '1px solid gray'}}>
                      <Grid item xs={3}> 
                      <Typography variant="body2">
                      <b>First Battle:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                      <Typography variant="body2">
                      {new Date(firstBattle).toISOString().split('T')[0]}  <br />
                      </Typography>
                      </Grid>
                      <Grid item xs={3}> 
                      <Typography variant="body2">
                      <b>Last Battle:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                      <Typography variant="body2">
                      {new Date(lastBattle).toISOString().split('T')[0]}  <br />
                      </Typography>
                      </Grid>
                  </Grid>
                  <Grid container sx={{mt: 1 }}>
                      <Grid item xs={4}> 
                        <Typography variant="body2">
                            <b>Total Win-Loss:</b> 
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="body2">
                          {winLossRecord}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}> 
                        <Typography variant="body2">
                            <b>Tournament Record:</b> 
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="body2">
                          {tournamentRecord}
                        </Typography>
                      </Grid>
                  </Grid>
                </CardContent>
            </Card>
                <MaterialReactTable table={table} /> 
            </Container>;
};

export default AppTable;
