import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Grid, Container, Card, CardContent } from '@mui/material';
import { useMatch  } from 'react-router-dom';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import events from  "../data/Events.json"
import battles from "../data/Battles.json";

const Example = () => {
  
    const match = useMatch("/:firstRoute/*");
    const lastRoute = match.params["*"];

    const [data, setData] = useState([]);
    const [name, setName] = useState("");
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState("");   

    const retrieveData = () => {
      
      var eventBattles = battles.filter(row => row.Event.Id == lastRoute);
     
      setData(eventBattles);

      var event = events.filter(row => row.Id == lastRoute);
      setName(event[0].Name);
      setDate(event[0].Date);
      setLocation(event[0].Location);


    };

      useEffect(() => {
        retrieveData();
      }, []);



  const columns = useMemo(
    () => [
      {
        header: 'Battle',
        accessorFn: (row) => row.Title,
        id: 'Title',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (

            <Typography sx={{ fontSize: 10}}>
            {row.original.Title} </Typography>

          ),
      },
      {
        header: 'Winner',
        accessorFn: (row) => row.Winner,
        id: 'Winner',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Typography  noWrap  sx={{ fontSize: 10, gap: '1rem' }}>
          {row.original.Winner} </Typography>

          ),
      }
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showColumnFilters: true,
      expanded: false, //expand all groups by default
    //  grouping: ['Year'], //an array of columns to group by by default (can be multiple)
      pagination: { pageIndex: 0, pageSize: 20 },
     // sorting: [{ id: 'Year', desc: false }], //sort defaults
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },

  });

  return <Container maxWidth="xs" sx={{mt: 3,mb: 3}}> 
              <Card sx={{ minWidth: 240,mb:1 }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {name}
                  </Typography>
                  <Grid container sx={{mt: 1}}>
                      <Grid item xs={6}> 
                      <Typography variant="body2">
                      <b>Date:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <Typography variant="body2">
                      {new Date(date).toISOString().split('T')[0]}
                      </Typography>
                      </Grid>
                      <Grid item xs={6}> 
                      <Typography variant="body2">
                      <b>Venue:</b> 
                      </Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <Typography variant="body2">
                      {location}
                      </Typography>
                      </Grid>
                  </Grid>
                </CardContent>
            </Card>
          <MaterialReactTable table={table}   />
        </Container>;
};

export default Example;
