import * as React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Facebook from '@mui/icons-material/Facebook';
import YouTube from '@mui/icons-material/YouTube';
import Web from '@mui/icons-material/Web';

import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function HomePage() {
  return (
    <Container  maxWidth="lg"        
    sx={{
        mt: 3,
        mb: 3
      }}>

        <Grid container spacing={5}>
            <Grid item xs={12} sm={5}>
                <Typography variant="h4" color="text.primary" gutterBottom>
                FlipTop Battle League
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{mb: 3, lineHeight: 2}} >
                This website contains the win-loss statistics for FlipTop Rap Battle League. 
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{mb: 3, lineHeight: 2}} >
                FlipTop is a Filipino Rap Battle League with the most viewed rap battles all over the world. 
                FlipTop have over 7 million subscribers and with over 2 billion total views on their YouTube channel 
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{mb: 3, lineHeight: 2}} >
                The league has been ongoing from 2010 up until today and is actively holding not just Rap Battle events but also 
                HipHop events in general
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{mb: 3, lineHeight: 2}} >
                This website is for all those League fans who are very interested to the competitors' (known as Battle Emcees for this league) win-loss records,
                which events, tournaments they participated and other information that will give insights into how the competitors are performing.
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 2}} >
                    To know more about FlipTop Rap Battle League, follow them on their social media platforms:
                </Typography>
                <a href="https://www.facebook.com/fliptop.battleleague/" target='blank' color="inherit">
                    <Facebook />
                </a>
                <a href="https://www.youtube.com/user/fliptopbattles" target='blank' color="inherit" sx={{ pl: 1, pr: 1 }}>
                    <YouTube />
                </a>
                <a href="https://fliptop.com.ph/" target='blank'color="inherit">
                    <Web />
                </a>
            </Grid>
            <Grid item xs={12} sm={5}>
                <Grid container spacing={4}>
                        <Grid item xs={12}>
                        <Card sx={{ minWidth: 240, bgcolor: '#f1f1f1' }}>
                        <CardContent>
        
                            <Typography variant="h6" component="div" gutterBottom>
                            Battle Emcee Standings
                            </Typography>
                            {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            this is where I will show the count of records
                            </Typography> */}
                            <Typography variant="body2">
                            The box contains win-loss record statistics of an individual competitor (battle emcee)
                            <br />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href="standings/emcee">Open the box</Button>
                        </CardActions>
                        </Card>
                        </Grid>

                        <Grid item xs={6}>
                        <Card sx={{ minWidth: 120, bgcolor: '#f1f1f1' }}>
                        <CardContent>
        
                            <Typography variant="h6" component="div" gutterBottom>
                            Team Standings
                            </Typography>
                            {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            this is where I will show the count of records
                            </Typography> */}
                            <Typography variant="body2">
                            The box contains win-loss record statistics of a team (could be a tandem, team of 3, or 5)
                            <br />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href="standings/team">Open the box</Button>
                        </CardActions>
                        </Card>
                        </Grid>

                        <Grid item xs={6}>
                        <Card sx={{ minWidth: 120, bgcolor: '#f1f1f1' }}>
                        <CardContent>
        
                            <Typography variant="h6" component="div" gutterBottom>
                            Events
                            </Typography>
                            {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            this is where I will show the count of records
                            </Typography> */}
                            <Typography variant="body2">
                            The box contains the history of battles (with the outcome) per events that happened in the league
                            <br />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href="events">Open the box</Button>
                        </CardActions>
                        </Card>
                        </Grid>

                        <Grid item xs={6}>
                        <Card sx={{ minWidth: 120, bgcolor: '#f1f1f1' }}>
                        <CardContent>
        
                            <Typography variant="h6" component="div" gutterBottom>
                            Isabuhay Tournaments
                            </Typography>
                            {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            this is where I will show the count of records
                            </Typography> */}
                            <Typography variant="body2">
                            The box contains the history of battles (with the outcome) per 1 on 1 (Isabuhay) tournament that happened in the league
                            <br />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href="tournament/isabuhay">Open the box</Button>
                        </CardActions>
                        </Card>
                        </Grid>

                        <Grid item xs={6}>
                        <Card sx={{ minWidth: 120, bgcolor: '#f1f1f1' }}>
                        <CardContent>
        
                            <Typography variant="h6" component="div" gutterBottom>
                            Dos Por Dos Tournaments
                            </Typography>
                            {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            this is where I will show the count of records
                            </Typography> */}
                            <Typography variant="body2">
                            The box contains the history of battles (with the outcome) per 2 on 2 (Dos Por Dos) tournament that happened in the league
                            <br />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href="tournament/dospordos">Open the box</Button>
                        </CardActions>
                        </Card>
                        </Grid>
                    </Grid>
                 </Grid>
        </Grid>  
    </Container>
  );
}