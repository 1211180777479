import { useMemo,useState,useEffect } from 'react';
import { Box, Stack, Typography, Grid, Container } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
//import { data } from './testdata';

import DosPorDosList from "../data/DosPorDosList.json";


const AppTable = () => {


    const [data, setData] = useState([]);
    //send api request here
    const retrieveData = () => {
        setData(DosPorDosList);
      };

      useEffect(() => {
        retrieveData();
      }, []);



  const columns = useMemo(
    () => [
      {
        header: 'Tournament',
        accessorFn: (row) => row.tournament,
        id: 'name',
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        size: 140,
        Cell: ({ row }) => (
          <a href={"dospordos/" + row.original.id }> {row.original.tournament}</a>
        ),
      },
      {
        header: 'Champion',
        accessorFn: (row) => row.champion,
        id: 'win',
        size: 120,
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (
            <div>
                <span> {row.original.champion.split("/")[0]}</span><br/>
                <span> {row.original.champion.split("/")[1]}</span>
            </div>
        )
      },
      {
        header: 'Finalist',
        accessorFn: (row) => row.finalist,
        id: 'lose',
        size: 120,
        enableColumnFilter: false,
        enableGrouping: false, 
        enableColumnActions: false,
        Cell: ({ row }) => (
            <div>
                <span> {row.original.finalist.split("/")[0]}</span><br/>
                <span> {row.original.finalist.split("/")[1]}</span>
            </div>
        )
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      expanded: false, //expand all groups by default
      pagination: { pageIndex: 0, pageSize: 50 },
      sorting: [{ id: 'name', desc: false }]
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
    
  });

  return  <Container maxWidth="xs" sx={{mt: 3,mb: 3}}> 
            <Typography variant="h6" color="text.primary" >
              FlipTop - Dos Por Dos Tournaments
            </Typography>
            <Typography variant="body2" color="text.secondary" >
                Please wait for the page to load
            </Typography>
            <MaterialReactTable table={table}   />
          </Container>;
};

export default AppTable;
